import React, { useContext } from "react"
import { Trans, Link } from "gatsby-plugin-react-i18next"
import { InView } from "react-intersection-observer"
// import PropTypes from "prop-types"
import { useTranslation } from "gatsby-plugin-react-i18next"

import "twin.macro"
import Container from "../container/fluid"
import logoMakeLife from "../../images/make-life-a-ride.svg"
import arrowUp from "../../images/icons/arrow-up.svg"
import Icon from "../icon"
import { InViewportContext } from "../../contexts/InViewportContext"

const Footer = props => {
  const { inViewport, setInViewport } = useContext(InViewportContext)
  const { t } = useTranslation()

  const handleOnchange = inView => {
    setInViewport(inView)
  }

  return (
    <footer>
      <Container bgGray200>
        <ul tw="flex flex-wrap md:flex-nowrap md:space-x-16 text-gray-500 py-5 md:py-0">
          <li tw="w-full md:w-auto">
            <Link tw="py-3 md:py-8 block hover:text-black" to="/privacy-policy">
              <Trans>footer_privacy_policy</Trans>
            </Link>
          </li>
          <li tw="w-full md:w-auto">
            <Link tw="py-3 md:py-8 block hover:text-black" to="/imprint">
              <Trans>footer_imprint</Trans>
            </Link>
          </li>
          <li tw="w-full md:w-auto">
            <button
              tw="py-3 md:py-8 block hover:text-black"
              onClick={() => {
                window.cookiefirst_show_settings()
              }}
            >
              <Trans>change_cookie_settings</Trans>
            </button>
          </li>
          <li tw="w-full md:w-auto">
            <a
              tw="py-3 md:py-8 hover:text-black flex items-center"
              href={t("footer_to_bmw_motorrad_days_2024")}
              target="_blank"
              rel="noreferrer nofollow"
            >
              BMW Motorrad Days 2025
              <Icon tw="w-4 ml-2 mt-1" svg={arrowUp} />
              <div className="logo-border"></div>
            </a>
          </li>
        </ul>
      </Container>
      <div tw="px-6">
        <InView as="div" onChange={inView => handleOnchange(inView)}>
          <div tw="ml-auto my-6 md:my-8 w-32 md:w-40 relative">
            <Icon tw="w-full" svg={logoMakeLife} />
            <div
              tw="border-2 border-black mr-1 mt-2 md:mt-3 opacity-0 transform translate-y-4 transition-all delay-200 duration-1000 ease-out absolute w-full"
              className={
                inViewport ? "logo-border logo-animation" : "logo-border"
              }
            ></div>
          </div>
        </InView>
      </div>
    </footer>
  )
}

// Footer.propTypes = {}

export default Footer
